import { Box, GoogleTagManager } from '@doist/marketist'
import { AppProps } from 'next/app'
import Head from 'next/head'

/** Imports Marketist CSS */
import '@doist/marketist/dist/index.css'
import '../styles/globals.css'
import css from '../styles/_app.module.css'

function App({ Component, pageProps }: AppProps): JSX.Element {
    return (
        <>
            <Head>
                <link rel="icon" sizes="160x160" type="image/svg" href="/favicon.svg" />
                <link rel="mask-icon" href="/favicon-mask.png" color="#121212" />

                <GoogleTagManager tagId="GTM-WKXRC7S" />
            </Head>
            <Box className={css.appContainer}>
                <Component {...pageProps} />
            </Box>
        </>
    )
}

export default App
